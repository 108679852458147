.circle {
    width: 300px;
    height: 300px;
    border-radius: 50%;
    padding-top: 40px;
    color: #000;
    margin-top: -80px;
    text-align: center;
    background: #fff;
    -webkit-border-radius: ;
    -moz-border-radius: ;
    -ms-border-radius: ;
    -o-border-radius: ;
}

.circle.missingFace {
    border: solid 4px red;
}

.circle.faceOK {
    border: dashed 4px blue;
}

.circle.faceOK.biggestEmotionneutral {
    border-color: #4545a0;
}

.circle.faceOK.biggestEmotionhappy {
    border-color: green;
}

.circle.faceOK.biggestEmotionsad {
    border-color: red;
}

.circle.faceOK.biggestEmotionangry {
    border-color: darkred;
}

.circle.faceOK.biggestEmotionsurprised {
    border-color: orange;
}

.circle.faceOK.biggestEmotiondisgusted {
    border-color: purple;
}

.circle.faceOK.biggestEmotionfearful {
    border-color: hotpink;
}

.canvasOnScreen {
    position: absolute;
    top: 0;
    left: 0;
}

.hideLiveVideo {
    position: abolute;
    width: 200px;
    margin-top: -500px;
    left: -2000px;
    opacity: 0 !important;
}

.showLiveVideo {
    position: abolute;
    margin-top: 40px;
    margin-bottom: 50%;
}

.videoCol {
    margin-left: 20px;
}

.analysisContainer {
    padding-top: 50px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.reverseCamera {
    position: absolute;
    right: 25px;
    border: 0px;
    outline: none !important;
    outline-offset: none !important;
}

.reverseCamera:focus {
    outline: none !important;
    outline-offset: none !important;
    box-shadow: none;
}