.utilBarWrapper {
    width: 100%;
    height: 50px;
    padding: 20px;
    text-align: right;
}

.utilBtn {
    border: 0px;
    outline: none !important;
    outline-offset: none !important;
}

.utilBtn:focus {
    outline: none !important;
    outline-offset: none !important;
    box-shadow: none;
}