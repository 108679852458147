body {
  height: 100%;
}

.titlebar {
  font-size: 30px;
  width: 100%;
  text-align: center;
  margin-right: 180px;
}

.bgImage {
  position: absolute;
  top: 0;
  left: 0;
  background-image: url("/img/proto3-art.jpg");
  filter: blur(5px);
  -webkit-filter: blur(5px);
  height: 100%;
  width: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  /*background-attachment: fixed;*/
}

.mainContainer {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color:  rgba(48, 44, 52, 0.8);
}

.partnerImg{
  text-align:right;
  max-height: 56px;
  right: 0px;
  position: absolute;
}


@media (max-width: 939px) {
  .titlebar {
    display:none;
  }
}