

body .csvgeneratorContainer,
body .resultChartsContainer {
    margin-top:24px;
    margin-bottom:16px;
    max-width:none;
}

.adminContainerDiv .rowTitle h2 {
    color:#FFF;
}
.adminContainerDiv .rowTitle {
    margin-bottom:10px;
}

.adminContainerDiv .row .col {
    width:180px;
    margin-left:5px;
    margin-right:5px;
    margin-bottom:15px;
}
.adminContainerDiv .row a {
    width:100%;
}

.adminContainerDiv .rowAll .col {
    width:360px;
}

.adminContainerDiv .rowAll a {
    background-color:red;
    border-color:red;
}

.adminContainerDiv .rowAgeRanges a {
    background-color:#09840b;
    border-color:#09840b;
}

.adminContainerDiv .rowWomens a {
    background-color:#FF1F98;
    border-color:#FF1F98;
}

.adminContainerDiv .rowMens a {
    background-color:#00A0FF;
    border-color:#00A0FF;
}
